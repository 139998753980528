// import commonEn from '../locales/common/en.json'
// import commonFr from '../locales/common/fr.json'
import loginEn from '../locales/login/en'
import loginFr from '../locales/login/fr'

export default {
    // en: Object.assign({}, commonEn, loginEn),
    // fr: Object.assign({}, commonFr, loginFr)
    en: loginEn,
    fr: loginFr
}
